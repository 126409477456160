import { Typography, useMediaQuery } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { isIOS, isMobileSafari, isSafari } from 'react-device-detect'
import { flockTheme } from '../../theme'

type ContributedBadgeProps = {
  month: number
  year: number
}

const ContributedBadge = (props: ContributedBadgeProps) => {
  const { month, year } = props
  const [showFraction, setShowFraction] = useState(true)
  const isMobile = useMediaQuery(flockTheme.breakpoints.down('sm'))
  useEffect(() => {
    if (isSafari || isMobileSafari || isIOS) {
      setShowFraction(false)
    } else {
      setShowFraction(true)
    }
  }, [])

  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Playfair Display',
          fontSize: '12px',
          lineHeight: '12px',
          fontWeight: '700',
          [flockTheme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '12px',
          },
        }}
      >
        {isMobile ? 'New' : 'Contributed'}
      </Typography>
      <br />
      <Typography
        variant="h1"
        sx={{
          fontSize: showFraction ? '32px' : '24px',
          lineHeight: showFraction ? '32px' : '24px',
          [flockTheme.breakpoints.down('sm')]: {
            fontSize: showFraction ? '16px' : '12px',
            lineHeight: showFraction ? '16px' : '12px',
          },
        }}
      >
        {showFraction ? (
          <>
            {month}&frasl;{year}
          </>
        ) : (
          <>
            {month}/{year}
          </>
        )}
      </Typography>
    </>
  )
}

export default ContributedBadge
