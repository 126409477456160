import React, { ReactNode } from 'react'
import {
  ContributedBadge,
  RightArrowIcon,
  TrackedButton,
} from '@flock/shared-ui'
import { Grid, Typography, useMediaQuery, Box } from '@mui/material'
import theme from '@flock/shared-ui/src/theme/theme'
import { ArchFrame } from '@flock/shared-ui/src/components/ArchFrame'
import { QuoteText } from '@flock/shared-ui/src/components/QuoteText'
import { BuildingFrame } from '@flock/shared-ui/src/components/BuildingFrame'
import Fade from 'react-reveal/Fade'
import WhatIsFlockUser from '../../images/what-is-flock-user.webp'
import WhatIsFlockAppPreview from '../../images/what-is-flock-app-preview.webp'
import SectionLayout from '../SharedComponents/SectionLayout'
import House1 from '../../images/houses/portfolio-house-1.webp'
import House2 from '../../images/house-interior.webp'
import { FAQ_PATH, ONBOARDING_PATH, PORTFOLIO_PATH } from '../../routeConstants'
import InlineMessageAddress from '../SharedComponents/InlineMessageAddress'
import { WebinarModal } from '../SharedComponents/WebinarModal'

type WhatIsFlockContentSectionProps = {
  firstRow?: {
    title: ReactNode
    description: ReactNode
    buttonText: string
    buttonOnClickPath: string
  }
  secondRow?: {
    title: string
    description: ReactNode
    buttonText: string
    buttonOnClickPath: string
  }
  thirdRow?: {
    title: string
    description: ReactNode
    buttonText: string
    buttonOnClickPath: string
  }
}

const WhatIsFlockContentSection = (props: WhatIsFlockContentSectionProps) => {
  const { firstRow, secondRow, thirdRow } = props
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <SectionLayout
        name="What-Is-Flock-Section-Row-1"
        backgroundColor="trustBlue.main"
      >
        <Grid item xs={12} sm={12} md={6}>
          <Fade duration={500} distance="5rem" left>
            {isTablet ? (
              <Box
                pb="16px"
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <BuildingFrame
                  src={House1}
                  badgeText={<ContributedBadge month={9} year={2022} />}
                  size={isMobile ? 'small' : 'medium'}
                  variant="standard"
                  state="CO"
                  city="Denver"
                  hasBoxShadow
                />
              </Box>
            ) : (
              <Box
                position="relative"
                width={{ md: '570px' }}
                height={{ md: '468px' }}
              >
                <Box
                  display="flex"
                  sx={{
                    float: 'left',
                  }}
                >
                  <BuildingFrame
                    src={House1}
                    badgeText={<ContributedBadge month={9} year={2022} />}
                    size="medium"
                    variant="detail"
                    city="Denver"
                    state="CO"
                    beds={4}
                    baths={3}
                    propertyType="Single family"
                    hasBoxShadow
                  />
                </Box>
                <Box position="absolute" bottom={0} right={0} zIndex={1}>
                  <BuildingFrame
                    src={House2}
                    size="small"
                    variant="standard"
                    city="Boulder"
                    state="CO"
                    hasBoxShadow
                  />
                </Box>
              </Box>
            )}
          </Fade>
        </Grid>
        {!isTablet ? <Grid item md={1} /> : null}
        <Grid item xs={12} sm={12} md={5} display="flex" alignItems="center">
          <Fade duration={500} distance="5rem" right>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ xs: '16px', sm: '32px' }}
            >
              <Typography variant="h2" textAlign="left" color="moneyGreen.main">
                {firstRow?.title}
              </Typography>
              <Typography variant="p1" textAlign="left" color="gray8.main">
                {firstRow?.description}
              </Typography>
              <Grid xs={8} md={8} item>
                <TrackedButton
                  to={firstRow?.buttonOnClickPath}
                  variant="secondary"
                  size="small"
                  sx={{
                    display: 'block',
                  }}
                >
                  {firstRow?.buttonText}
                </TrackedButton>
              </Grid>
            </Box>
          </Fade>
        </Grid>
      </SectionLayout>
      <SectionLayout
        name="What-Is-Flock-Section-Row-2"
        backgroundColor="trustBlue.main"
      >
        <Grid item xs={12} sm={12} md={6} order={{ sm: 1, xs: 1, md: 3 }}>
          <Fade duration={500} distance="5rem" right>
            <Box pb="16px" display="flex" justifyContent="center">
              <ArchFrame
                src={WhatIsFlockUser}
                size={(() => {
                  if (isMobile) return 'small'
                  if (isTablet) return 'medium'
                  return 'large'
                })()}
                hasBoxShadow
              >
                <Box display="flex" alignItems="center" height="100%">
                  <QuoteText
                    text="I really wanted to retire from being a landlord."
                    variant="inline"
                    isSmall={isTablet}
                  />
                </Box>
              </ArchFrame>
            </Box>
          </Fade>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          order={{ sm: 2, xs: 2, md: 1 }}
          display="flex"
          alignItems="center"
        >
          <Fade duration={500} distance="5rem" left>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ xs: '16px', sm: '32px' }}
            >
              <Typography variant="h2" textAlign="left" color="moneyGreen.main">
                {secondRow?.title}
              </Typography>
              <Typography variant="p1" textAlign="left" color="gray8.main">
                {secondRow?.description}
              </Typography>
              <Grid md={6} xs={7} item>
                <TrackedButton
                  to={secondRow?.buttonOnClickPath}
                  variant="secondary"
                  size="small"
                  sx={{
                    display: 'block',
                  }}
                >
                  {secondRow?.buttonText}
                </TrackedButton>
              </Grid>
            </Box>
          </Fade>
        </Grid>
        {!isTablet ? <Grid item md={1} order={{ md: 2 }} /> : null}
      </SectionLayout>
      <SectionLayout
        name="What-Is-Flock-Section-Row-3"
        backgroundColor="trustBlue.main"
      >
        <Grid item xs={12} sm={12} md={6}>
          <Fade duration={500} distance="5rem" left>
            <Box pb="16px" display="flex" justifyContent="center">
              <img
                src={WhatIsFlockAppPreview}
                alt="Flock App Preview"
                style={{
                  width: (() => {
                    if (isMobile) return '266px'
                    if (isTablet) return '416px'
                    return '543px'
                  })(),
                  height: (() => {
                    if (isMobile) return '246px'
                    if (isTablet) return '383px'
                    return '500px'
                  })(),
                }}
              />
            </Box>
          </Fade>
        </Grid>
        {!isTablet ? <Grid item md={1} /> : null}
        <Grid item xs={12} sm={12} md={5} display="flex" alignItems="center">
          <Fade duration={500} distance="5rem" right>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ xs: '16px', sm: '32px' }}
            >
              <Typography variant="h2" textAlign="left" color="moneyGreen.main">
                {thirdRow?.title}
              </Typography>
              <Typography variant="p1" textAlign="left" color="gray8.main">
                {thirdRow?.description}
              </Typography>
            </Box>
          </Fade>
        </Grid>
        <Grid item xs={12}>
          <InlineMessageAddress
            text="Landlords, retire and minimize taxes."
            variant={isTablet ? 'button' : 'address'}
            label="Get Started"
          />
        </Grid>
        <Grid item xs={12} textAlign="end" pt="16px">
          <WebinarModal />
        </Grid>
      </SectionLayout>
    </>
  )
}

// getting component is not function error if we don't do this.
const FirstRowTitle = () => (
  <>
    Your house{' '}
    <Box sx={{ display: 'inline-block', top: '6px', position: 'relative' }}>
      <RightArrowIcon color="moneyGreen" />
    </Box>
    Flock&apos;s fund.
  </>
)

const ThirdRowDescription = () => (
  <>
    With Flock, we&apos;ve designed a product that allows you to make your
    transition to passive ownership.
    <br />
    <br />
    Exchange your property for ownership in Flock&apos;s fund once and receive
    passive income forever!
  </>
)

export default WhatIsFlockContentSection

WhatIsFlockContentSection.defaultProps = {
  firstRow: {
    title: <FirstRowTitle />,
    description:
      'When you join Flock, your property is added to our portfolio of homes across the country. These assets vary in terms of price point, yield, and appreciation potential, creating a diversified investment that decreases the risk of owning one individual house.',
    buttonText: 'See our portfolio',
    buttonOnClickPath: PORTFOLIO_PATH,
  },
  secondRow: {
    title: 'You have ownership of a portfolio of rentals.',
    description:
      'Flock brings institutional-caliber expertise to individual owners like you. It’s hard to generate outsized returns if owning rental property is your part-time job, but our team of real estate professionals provide industry expertise and economics of scale for your benefit. Instead of stressing about the costs of ownership, you just sit back, relax, and collect, while we handle all of the work for you.',
    buttonText: 'See our FAQ',
    buttonOnClickPath: FAQ_PATH,
  },
  thirdRow: {
    title: 'Your client experience.',
    description: <ThirdRowDescription />,
    buttonText: 'Get started',
    buttonOnClickPath: ONBOARDING_PATH,
  },
}
